import React, { useState } from "react"
import Layout from "../../../components/layouts"
import { Link } from "gatsby"
import FsLightbox from "fslightbox-react"
import Banner from '../../../components/features/Banner'
import Question from '../../../components/features/Question'
import Video from '../../../components/features/Video'
import Info from '../../../components/features/Info'
import Steps from '../../../components/features/Steps'
import SEO from '../../../components/common/SEO';
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query SafetyTraining {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
  `

export default ({data}) => {
  const [toggler, setToggler] = useState(false)
    const lightboxProps = {
        toggler: toggler,
        setToggler: setToggler,
        sources: ["https://www.youtube.com/watch?v=nC17rj0ISxs"],
    }

    const bannerProps = {
        title: 'Online Training Courses',
        subtitle: `Choose from over 60 pre-built safety awareness courses to
        create your own custom cloud course. Assure full comprehension'
        with custom quizzes, adjustable pass rates and compliance tracking across your organization. Training workers has
        never been easier!`,
        featureName: 'Training',
        rightButtonText: 'View Course List',
        rightButtonSrc: '/features/training/',
        mockupImg: '/features/training-courses/feature-page-header-custom-training.png'
    }

    const questionProps = {
        question: 'Are your training manuals being used as a pillow?',
        answer: `Properly training workers has been a struggle since the dawn
        of time and even though it received a major update with the
        introduction of video training it's still challenging to
        properly host, quiz and track. Some of the common training
        issues Workhub set out to solve are:`,
        answerBulletList: [
            'Costly to host training sessions on-site or in class',
            'Challenging to train large groups and test comprehension',
            'Unable to track workers progress',
            'Tough to motivate workers to take optional training',
        ],
        imageSrc: '/features/sketch/training-large.jpg',
        imgAlt: 'Cartoon of man teaching a Safety Course in a oldschool classroom setting with a bored student'
    }

    const videoProps = {
        videoSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
        videoImage: '/features/training-courses/training-courses-thumbnail.jpg',
        videoStatement: (
          <strong>Free Online Training Courses</strong>)
    }

    const infoProps = {
        infoList: [
            {
                mockupImage: '/features/training-courses/training-course-admin-custom.jpg',
                infoTitle: 'Create and Host Custom Courses with Ease',
                infoSubtitle: `Create courseware custom to your equipment, processes,
                regulations with individual lessons, quizzes, downloadable
                handouts and custom wallet card and certificate for your
                workers.`,
                infoBullets: [
                    {
                        name: 'Customize Your Courses:',
                        description: `Set expiration dates,
                        pass rates, let workers challenge the quiz, and many more
                        options available for each course.`
                    },
                    {
                        name: 'Training Matrix:',
                        description: `Add course requirements based
                        on job role throughout your company. When new workers are
                        hired they can easily see what's required of them.`,
                    },
                    {
                        name: 'Check the Catalog:',
                        description: `Browse our ever growing
                        catalog of training and decide which courses you want to
                        make required for your workers and which ones you want to
                        make optional.`,
                    }
                ],
            },
            {
                mockupImage: '/features/training-courses/training-course-admin-compliance.jpg',
                infoTitle: 'Track Compliance with Custom Fail Rates',
                infoSubtitle: `Advanced settings are available for each course including the required passing/fail (your preference) grade and you can assign courses to your workers to track completion dates and compliance. Get notified when courses are coming due so that you never send a worker to site without proper training.`,
                infoBullets: [
                    {
                        name: 'Compliance Monitoring:',
                        description: `Training dates,
                        expirations, and quiz results are all tracked in Workhub.`
                    },
                    {
                        name: 'Notifications:',
                        description: `Your safety team and your
                        workers will be notified when course expirations are coming
                        due.`,
                    },
                    {
                        name: 'Get Feedback:',
                        description: `Get feedback on the courses to
                        gain valuable insight from your workers.`,
                    }
                ],
            }
        ]
    }

    const stepsProps = {
        title: 'Training Workers is as Easy as 1,2,3',
        subtitle: `Training courses are easily accessible on all devices/platforms
        for your workers.`,
        stepList: [
            {
                title: 'Check the Dashboard',
                subtitle: 'Quickly see pending items that require attention',
                imageSrc: '/features/training-courses/training-course-step-1-upload.jpg'
            },
            {
                title: 'Perform the Training',
                subtitle: `Study the training and complete the quiz with a passing
                score`,
                imageSrc: '/features/training-courses/training-course-step-2.jpg'
            },
            {
                title: 'Gain Compliance',
                subtitle: 'Gain complete compliance across your organization',
                imageSrc: '/features/training-courses/training-course-step-3.jpg'
            }
        ]
    }

    const seo = { 
        title: 'Workhub | 60+ Free Online Safety Training Courses',
        description: 'We offer 60+ free online safety training courses with our safety management software. Custom courses, compliance tracking & training courses for a wide-range of industries.',
        image: '/features/training-courses/social-card.jpg',
        // pathname,
        // article
    }

    const featureIndex = [
        {
          title: "Over 60+ courses",
          para: "Choose from our library of pre-built safety awareness courses.",
          link: '/features/training/',
        },
        {
          title: "Customize Your Courses",
          para: "Set expiration dates, pass rates, and let workers challenge quizzes + many more options available for each course.",
        },
        {
          title: "Assign by role",
          para: "Add course requirements based on job role. New workers can easily see what is required of them.",
        },
        {
            title: "Designate Optional/Required",
            para: "Decide which courses you want to make required for your workers and which ones you want to make optional.",
          },
          {
            title: "Notifications",
            para: "Your safety team and your workers will be notified when course expirations are coming due.",
          },
          {
            title: "Compliance Monitoring",
            para: "Training dates, expirations, and quiz results are all tracked in Workhub.",
          },
          {
            title: "Get Feedback",
            para: "Get feedback on the courses to gain valuable insight from your workers.",
          },
      ]

  return (
    <Layout {...lightboxProps}>
        <SEO {...seo} {...data.site}/>
        <Banner {...bannerProps} {...lightboxProps}/>
        <Question {...questionProps} />
        <Video {...videoProps} {...lightboxProps} />
        <Info {...infoProps} />
        <Steps {...stepsProps} />

             {/* FEATURE OVERVIEW */}

             <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid" style={{marginBottom: '60px'}}>
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} link={item.link} />
            ))}
          </div>
        </section>

    </Layout>
  )
}
